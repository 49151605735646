import Forms from '../pages/Forms';
import Home from '../pages/Home/index';

const routes = [
    {
        path: ":company_name/:form_number",
        component: Forms,
    },
    {
        path: "",
        component: Home,
    },
]

export default routes;