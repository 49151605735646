import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import MapQuestions from '../MapQuestions'

import setQuestionWithId from '../_utils/setQuestionWithId'
import focusNextQuestionFromSelect from '../_utils/focusNextQuestionFromSelect'

import SelectMenu from 'react-select'

const Loop = ({question, answers, onChange, nest, errors, loopIndex, config, form, getState}) => {

    const maxLoop = question.answer_loop_length ? question.answer_loop_length : config.DEFAULT_LOOP_LENGTH

    const [_question] = useState(setQuestionWithId(question, loopIndex))
    const [options, setOptions] = useState([])
    const [val, setVal] = useState('')
    const [maps, setMaps] = useState([])

    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const getOptions = useCallback(() => {
        let _options = [];
        for (let i = 0; i <= maxLoop; i++) {
            _options.push({value: i.toString(), label: i.toString()})           
        }
        setOptions(_options)
    }, [maxLoop])

    const setMapQuestions = useCallback((value) => {
        if(!value || parseInt(value) === 0) return setMaps([])
        let _maps = [];

        for (let i = 0; i < value; i++) {
            _maps.push(
                <MapQuestions 
                    key={i}
                    _questions={_question.questions} 
                    onChange={onChange}
                    answers={answers}
                    form={form}
                    nest={nest + 1}
                    loopIndex={i + 1}
                    errors={errors}
                    parentIsLoop={true}
                    config={config}
                    getState={getState}
                />
            )
            
        }
        setMaps(_maps)
    }, [_question, onChange, answers, nest, form, errors, config, getState])
   
    const handleChange = useCallback((e) => {
        const value = e.value && e.value === '__FALSE__' ? '' : e.value;
        setVal(value);
        onChange(_question, value)
        setMapQuestions(value)
        if(value) {
            setTimeout(() => {
                focusNextQuestionFromSelect(value, _question);
            }, 250)
        }
    }, [_question, onChange, setMapQuestions])

    // whenever errors change rerender questions to show the errors
    useEffect(() => {
        setMapQuestions(val)
    }, [errors, setMapQuestions, val])

    useEffect(() => {
        if(val) return;
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        const value = answers[formattedQuestion.id];
        setVal(value || '')
        getOptions()
        setMapQuestions(value);
    }, [question, loopIndex, answers, getOptions, setMapQuestions, val])

    return (
        <>
        <FormGroup>        
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {_question.name}
            </label>
            <SelectMenu 
                options={options}           
                onChange={handleChange}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                openMenuOnFocus={true}
                value={options.find(option => parseInt(option.value) === parseInt(val))}
            />
            <input type="hidden" id={_question.id} />
        </FormGroup>  
        {maps}
        </>
    )
}

export default memo(Loop);