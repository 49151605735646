import { memo, useState, useEffect, useCallback } from 'react';
import { FormGroup, Form } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import setQuestionWithId from '../_utils/setQuestionWithId'

import MapQuestions from '../MapQuestions'

const Checkbox = ({question, answers, onChange, loopIndex, errors, form, nest, config, getState}) => {

    const [possibleAnswers, setPossibleAnswers] = useState([])
    const [val, setVal] = useState([])
    const [ids, setIds] = useState([])
    const [_question, _setQuestion] = useState({answers: []})

    const hasErr = errors.includes(_question.id) && !val.length ? true : false;

    const fetchAnswers = useCallback(() => {
        let _possibleAnswers = [];
        const _state = getState();
        _question.possible_answers.forEach(answer => {
            if(_state[answer]) _possibleAnswers.push(_state[answer])
        })
        setPossibleAnswers(_possibleAnswers)
    }, [getState, _question])

    const handleChange = useCallback((answer) => {
        let _val = JSON.parse(JSON.stringify(val));

        // account for question with answer and then form change
        if(!Array.isArray(_val)) _val = [];

        if(_val.includes(answer)) {
            _val = _val.filter(v => v !== answer);
        } else {
            _val.push(answer)
        }

        setVal(_val)
        onChange(_question, _val)
        fetchAnswers()
    }, [_question, onChange, val, fetchAnswers])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        setVal(answers[formattedQuestion.id] || [])

        let _ids = [];
        question.answers.forEach(() => _ids.push(uuidv4()))
        setIds(_ids)



    }, [question, loopIndex, answers])

    useEffect(() => {
        if(!ids.length) return;

        if(_question.possible_answers && _question.possible_answers.length) {
            const inner = document.getElementById(`${_question.id}-inner`)
            inner.addEventListener('mouseenter', fetchAnswers);

            return () => {
                inner.removeEventListener('mouseenter', fetchAnswers)
            }
        }
    }, [ids, _question.id, _question.possible_answers, fetchAnswers])

    if(!ids.length) return <></>;

    return (                    
        <Form id={_question.id} onSubmit={(e) => e.preventDefault()}>
            <FormGroup id={`${_question.id}-inner`} >        
                <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                    {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                    {_question.name}
                </label>
                {question.description ? (
                    <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
                ) : null}
                {_question.answers.map((answer, i) => {
                    const selected = val.includes(answer)
                    return (
                        <div key={i} className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={ids[i]}
                                type="checkbox"
                                onChange={() => handleChange(answer)}
                                checked={selected}
                                // onBlur={() => setActive(false)}
                                // onFocus={() => console.log('her')}
                            />
                            <label className="custom-control-label" htmlFor={ids[i]}>
                                {answer}
                            </label>
                        </div>
                    )
                })}
                {possibleAnswers && possibleAnswers.length ? possibleAnswers.map((answer, i) => {
                    if(!answer || (answer && !answer.trim())) return null;
                    const selected = val.includes(answer)
                    const _id = uuidv4();
                    return (
                        <div key={i} className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk-custom-checkbox-${_id}`}
                                type="checkbox"
                                onChange={() => handleChange(answer)}
                                checked={selected}
                                // onBlur={() => setActive(false)}
                                // onFocus={() => console.log('her')}
                            />
                            <label className="custom-control-label" htmlFor={`archk-custom-checkbox-${_id}`}>
                                {answer}
                            </label>
                        </div>
                    )
                }) : null}
            </FormGroup>  
            {/* account for question with answer and then form change */}
            {val && Array.isArray(val) && val.some(v =>_question.branch_on_answers.includes(v) )? (
                <MapQuestions 
                    _questions={_question.questions} 
                    onChange={onChange}
                    answers={answers}
                    form={form}
                    nest={nest + 1}
                    loopIndex={loopIndex}
                    errors={errors}
                    config={config}
                />
            ) : null}
        </Form>
    )
}

export default memo(Checkbox);