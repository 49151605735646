import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Input, Row, Col, Form } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import simulateTabOnEnter from '../_utils/simulateTabOnEnter'
import focusNextQuestionFromSelect from '../_utils/focusNextQuestionFromSelect'

import SelectMenu from 'react-select';

const Address = (props) => {

    const {question, answers, onChange, setActive, loopIndex, errors, config} = props
    
    const [_question, _setQuestion] = useState({})
    const [val, setVal] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: ''
    })

    const { stateOptions } = config;
    const addressId = _question.id + 'address';
    const hasAddressError = errors.includes(_question.id) ? true : false;
    const hasCityError = errors.includes(_question.id) && !val.city ? true : false;
    const hasStateError = errors.includes(_question.id) && !val.state ? true : false;
    const hasZipError = errors.includes(_question.id) && !val.zip ? true : false;

    const handleStateChange = useCallback((e) => {
        const value = e.value && e.value === '__FALSE__' ? '' : e.value;

        const _val = JSON.parse(JSON.stringify(val));
        _val['state'] = value;
        setVal(_val);
        onChange(_question, _val)

        focusNextQuestionFromSelect(value, {id: addressId});
    }, [_question, onChange, val, addressId])


    const handleChange = useCallback((field, e) => {
        const _val = JSON.parse(JSON.stringify(val));
        _val[field] = e.target.value;
        setVal(_val);
        onChange(_question, _val)
    }, [_question, onChange, val])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        if(answers[formattedQuestion.id]) {
            setVal(answers[formattedQuestion.id])
        }
    }, [question, loopIndex, answers])

    return (
        <Form>

            <div id={_question.id}>
                <FormGroup>
                    <label className="form-control-label">{_question.name}</label>
                    {question.description ? (
                        <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
                    ) : null}
                </FormGroup>  
                
                <Row className="question-address-wrapper">
                    <Col lg={6}>
                        <FormGroup key={_question._id}>
                            <label className={`form-control-label ${hasAddressError ? 'text-danger' : ''}`}>
                                {hasAddressError ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                                Address Line 1
                            </label>
                            <Input 
                                autoComplete="address-line1"
                                value={val.address_line_1}
                                type="text"
                                name="address-line1"
                                onChange={(e) => handleChange('address_line_1', e)}
                                onBlur={() => setActive(false)}
                                onFocus={() => setActive(true)}
                                onKeyDown={(e) => simulateTabOnEnter(e, _question)}
                                invalid={hasAddressError}
                            />
                        </FormGroup>  
                    </Col>
                    <Col lg={6}>
                        <FormGroup key={_question._id}>
                            <label className="form-control-label">Address Line 2</label>
                            <Input 
                                name="address-line2"
                                autoComplete="address-line2"
                                value={val.address_line_2}
                                type="text"
                                onChange={(e) => handleChange('address_line_2', e)}
                                onBlur={() => setActive(false)}
                                onFocus={() => setActive(true)}
                                onKeyDown={(e) => simulateTabOnEnter(e, true)}
                            />
                        </FormGroup>  
                    </Col>
                </Row>
                
                <Row className="question-address-wrapper">
                    <Col lg={4}>
                        <FormGroup key={_question._id}>
                            <label className={`form-control-label ${hasCityError ? 'text-danger' : ''}`}>
                                {hasCityError ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                                City
                            </label>
                            <Input 
                                autoComplete="address-level2"
                                name="address-level2"
                                value={val.city}
                                type="text"
                                onChange={(e) => handleChange('city', e)}
                                onBlur={() => setActive(false)}
                                onFocus={() => setActive(true)}
                                onKeyDown={simulateTabOnEnter}
                                invalid={hasCityError}
                            />
                        </FormGroup>  
                    </Col>
                    <Col lg={4}>
                        <FormGroup key={_question._id}>
                            <label className={`form-control-label ${hasStateError ? 'text-danger' : ''}`}>
                                {hasStateError ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                                State
                            </label>
                            <SelectMenu 
                                options={stateOptions}           
                                onChange={handleStateChange}
                                menuPortalTarget={document.body} 
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                openMenuOnFocus={true}
                                value={stateOptions.find(option => option.value === val.state)}
                            />
                            <Input 
                                type="text" 
                                style={{maxHeight: 0, padding: 0, border: 'none'}} 
                                autoComplete="address-level1" 
                                id={addressId} 
                                name="address-level1"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if(!value) return;
                                    const option = stateOptions.find(o => o.value.toLowerCase() === value.toLowerCase());
                                    if(option) handleStateChange(option);
                                }} 
                            />
                        </FormGroup>  
                    </Col>
                    <Col lg={4}>
                        <FormGroup key={_question._id}>
                            <label className={`form-control-label ${hasZipError ? 'text-danger' : ''}`}>
                                {hasZipError ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                                Zip Code
                            </label>
                            <Input 
                                name="postal-code"
                                autoComplete="postal-code"
                                value={val.zip}
                                type="text"
                                onChange={(e) => handleChange('zip', e)}
                                onBlur={() => setActive(false)}
                                onFocus={() => setActive(true)}
                                onKeyDown={simulateTabOnEnter}
                                invalid={hasZipError}
                            />
                        </FormGroup>  
                    </Col>
                </Row>
                
            </div>
        </Form>
    )
}

export default memo(Address);