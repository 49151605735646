import { memo, useState } from 'react';

import Text from './questions/Text'
import Phone from './questions/Phone'
import Number from './questions/Number'
import Textarea from './questions/Textarea'
import Address from './questions/Address'
import Select from './questions/Select'
import State from './questions/State'
import Checkbox from './questions/Checkbox'
import Date from './questions/Date'
import Upload from './questions/Upload'
import YesNo from './questions/YesNo'
import Signature from './questions/Signature'
import Loop from './questions/Loop'
import MonthLoop from './questions/MonthLoop'
import TwoWeekLoop from './questions/TwoWeekLoop'
import OneWeekLoop from './questions/OneWeekLoop'
import SocialSecurity from './questions/SocialSecurity'

import getNestIdentifier from './_utils/getNestIdentifier'

const Question = ({question, i, onChange, answers, form, errors, nest = 0, loopIndex = 0, config, getState}) => {

    const [active, setActive] = useState(false)

    return (
        <div className={`question-wrapper ${active ? 'active' : ''}`}>
            <p className="text-sm mb-0">
                <span className='font-weight-light question-number'> {nest === 0 ? 'Question' : "Part"}  {getNestIdentifier(nest, i)}</span>
            </p>

            {question.type === 'address' ? (
                <Address
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    config={config} 
                />
            ) : question.type === 'select' ? (
                <Select
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    nest={nest}
                    form={form}
                    config={config}
                    getState={getState}
                />
            ) : question.type === 'state' ? (
                <State
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    nest={nest}
                    form={form}
                    config={config}
                />
            ) : question.type === 'checkbox' ? (
                <Checkbox
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    nest={nest}
                    form={form}
                    config={config}
                    getState={getState}
                />
            ) : question.type === 'text' ? (
                <Text
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    config={config}
                />
            ) : question.type === 'phone' ? (
                <Phone
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    config={config}
                />
            ) : question.type === 'number' ? (
                <Number
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    config={config}
                    getState={getState}
                />
            ) : question.type === 'textarea' ? (
                <Textarea
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    config={config}
                />
            ) : question.type === 'date' ? (
                <Date
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                />
            ) : question.type === 'upload' ? (
                <Upload
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    form={form}
                />
            ) : question.type === 'yes-no' ? (
                <YesNo
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                    nest={nest}
                    form={form}
                    config={config}
                    getState={getState}
                />
            ) : question.type === 'signature' ? (
                <Signature
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    loopIndex={loopIndex}
                />
            ) : question.type === 'loop' ? (
                <Loop
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    nest={nest}
                    loopIndex={loopIndex}
                    form={form}
                    config={config}
                    getState={getState}
                />
            ) : question.type === 'month-loop' ? (
                <MonthLoop
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    nest={nest}
                    loopIndex={loopIndex}
                    form={form}
                    config={config}
                    getState={getState}
                />
            ) :  question.type === 'two-week-loop' ? (
                <TwoWeekLoop
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    nest={nest}
                    loopIndex={loopIndex}
                    form={form}
                    config={config}
                    getState={getState}
                />
            ) : question.type === 'one-week-loop' ? (
                <OneWeekLoop
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    nest={nest}
                    loopIndex={loopIndex}
                    form={form}
                    config={config}
                    getState={getState}
                />
            ) : question.type === 'social-security' ? (
                <SocialSecurity
                    question={question}
                    setActive={setActive}
                    errors={errors}
                    answers={answers}
                    onChange={onChange}
                    nest={nest}
                    loopIndex={loopIndex}
                    form={form}
                    config={config}
                    getState={getState}
                />
            ) : <div className='bg-danger text-white'>invalid question type</div>}

            <div className="question-separator"></div>
            
        </div>    
    )

}

export default memo(Question);