import LOGO from 'assets/img/brand/ZapReportsCut.png'

let keys = {
    LOGO,
    COMPANY: 'Zap Reports',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'forms.zapreports.com') {

    keys = {

        ...keys,
        
        API_URL                   : 'https://api.zapreports.com',
        SITE_URL                  : 'https://zapreports.com',
        SYSTEM_API_KEY            : 'architeck-58163098451',

    }

//using staging keys
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        API_URL                   : 'https://staging.api.zapreports.com',
        SITE_URL                  : 'https://staging.zapreports.com',
        SYSTEM_API_KEY            : 'architeck-946154718',

    }

//using development keys
} else {

    keys = {

        ...keys,

        API_URL                   : 'http://localhost:5004',
        SITE_URL                  : 'http://localhost:3003',
        SYSTEM_API_KEY            : 'architeck-',

    }
}

export default keys
