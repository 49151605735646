
import apiWorker from '../apiWorker'

const forms =  {
    submit          : (data)                    => apiWorker('post',   `/v1/api/forms/submit`, data, true),
    findByNumber    : (company_id, form_number) => apiWorker('get',   `/v1/api/forms/number/${company_id}/${form_number}`, null, true),
    viewDocument    : (Key)                     => apiWorker('get',   `/v1/api/forms/documents/key?Key=${Key}`, null, true),
    getConfig       : ()                        => apiWorker('get',   `/v1/api/forms/configuration`, null, true),

}

export default forms;