import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import simulateTabOnEnter from '../_utils/simulateTabOnEnter'


const Number = ({question, answers, onChange, loopIndex, setActive, errors, config, getState}) => {

    const [val, setVal] = useState('')
    const [_question, _setQuestion] = useState({})
    const [total, setTotal] = useState(undefined)

    const length = val ? val.length : 0;
    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const onFocus = useCallback(() => {
        setActive(true)
        const _state = getState()
        const forQuestionTotal = _question.use_total_for
        if(!forQuestionTotal) return
        const total = _state[forQuestionTotal + '-total']

        if(total !== undefined) {
            setTotal(total)
        }

    }, [_question, setActive, getState])

    const handleChange = useCallback((e) => {
        if(!e.target.validity.valid) return;
        let value = e.target.value;
        // if(value) value = value.trim();
        if(value && value.length > config.MAX_NUMBER_LENGTH) value = value.slice(0,config.MAX_NUMBER_LENGTH)
        setVal(value);
        onChange(_question, e)
    }, [_question, onChange, config.MAX_NUMBER_LENGTH])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        setVal(answers[formattedQuestion.id] || '')
    }, [question, loopIndex, answers])
    return (
        <>
            <FormGroup>        
                <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                    {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                    {_question.name}
                </label>
                {question.description ? (
                    <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
                ) : null}

                {total !== undefined ? (
                    <p className="text-sm mb-0">
                        <i className="fas fa-info-circle text-success mr-2 " /> Estimated Total Based On Question Answers: {total.toFixed(2)}
                    </p>
                ) : null}
                <Input 
                    pattern="^\d*(\.\d{0,2})?$"
                    id={_question.id}
                    value={val}
                    type="text"
                    onChange={handleChange}
                    invalid={hasErr}
                    onBlur={() => setActive(false)}
                    onFocus={onFocus}
                    onKeyDown={simulateTabOnEnter}
                />
                {length > 20 ? (
                    <p className="text-right character-count">
                        <span className={length >= config.MAX_NUMBER_LENGTH ? 'text-warning' : ''}>{length} / {config.MAX_NUMBER_LENGTH} characters</span>
                    </p>
                ) : null}
                
            </FormGroup>  
        </>
    )
}

export default memo(Number);