import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'

const Textarea = ({question, answers, onChange, loopIndex, setActive, errors, config}) => {

    const [val, setVal] = useState('')
    const [_question, _setQuestion] = useState({})

    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const length = val ? val.length : 0;

    const handleChange = useCallback((e) => {
        let value = e.target.value;
        // if(value) value = value.trim();
        if(value && value.length > config.MAX_TEXTAREA_LENGTH) value = value.slice(0,config.MAX_TEXTAREA_LENGTH)
        setVal(value);
        onChange(_question, e)
    }, [_question, onChange, config.MAX_TEXTAREA_LENGTH])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        setVal(answers[formattedQuestion.id] || '')
    }, [question, loopIndex, answers])

    return (
        <FormGroup>        
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {_question.name}
            </label>
            {question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
            ) : null}
            <Input 
                id={_question.id}
                style={{height: 100}}
                value={val}
                type="textarea"
                onChange={handleChange}
                onBlur={() => setActive(false)}
                onFocus={() => setActive(true)}
                invalid={hasErr}
            />
            <p className="text-right character-count">
                <span className={length >= config.MAX_TEXTAREA_LENGTH ? 'text-warning' : ''}>{length} / {config.MAX_TEXTAREA_LENGTH} characters</span>
            </p>
        </FormGroup>
    )
}

export default memo(Textarea);