import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import simulateTabOnEnter from '../_utils/simulateTabOnEnter'

import ReactTelephoneInput from 'react-telephone-input'
import phone from 'phone';

import 'react-telephone-input/css/default.css'


const Phone = ({question, answers, onChange, loopIndex, setActive, errors, config}) => {

    const maxLength = question.is_possible_answer ? 30 : config.MAX_TEXT_LENGTH

    const [hideInput, setHideInput] = useState(false)
    const [val, setVal] = useState('')
    const [_question, _setQuestion] = useState({})

    const valid = phone(val)

    const hasErr = errors.includes(_question.id) && !valid[0] ? true : false;

    const handleChange = useCallback((e) => {
        let value = e;
        // if(value) value = value.trim();
        if(value && value.length > maxLength) value = value.slice(0,maxLength)
        setVal(value);
        onChange(_question, value)
    }, [_question, onChange, maxLength])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        const _answer = answers[formattedQuestion.id] || '';
        setVal(_answer)
        _setQuestion(formattedQuestion);

        // for input to rerender if we have an answer
        if(_answer) {
            setHideInput(true);
            setTimeout(() => setHideInput(false), 500)
        }
    }, [question, loopIndex, answers])

    return (
        <>
            <FormGroup>        
                <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                    {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                    {_question.name}
                </label>
                {question.description ? (
                    <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
                ) : null}

                {hasErr ? <p className="text-danger mb-0">Please enter a valid phone number</p> : null}

                {hideInput ? null : (
                    <ReactTelephoneInput 
                        initialValue={val}
                        defaultCountry="us"
                        classNames="react-phone-input"
                        flagsImagePath="/flags.png"
                        preferredCountries={['us']}
                        onEnterKeyPress={simulateTabOnEnter}
                        onChange={handleChange}

                    />
                )}

            </FormGroup>  
        </>
    )
}

export default memo(Phone);