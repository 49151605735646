/*

// possible question types
age
number
state


*/

import React, { useState, useEffect, useCallback, useReducer, useRef } from 'react';
// import { Redirect } from 'react-router-dom';
import { Container, Form, Row, Col } from 'reactstrap';
import Circle from 'components/markup/loading/Circle'

import _form_answers from '_functions/form_answers'
import _forms from '_functions/forms'

import MapQuestions from './MapQuestions'
import StepTracker from './StepTracker'
import PageProgress from './PageProgress'

import getProgressAndErrors from './_utils/getProgressAndErrors'
import getRootId from './_utils/getRootId'
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

const useEnhancedReducer = (reducer, initState, initializer) => {
    const lastState = useRef(initState)
    const getState = useCallback(() => lastState.current, [])
    return [
      ...useReducer(
        (state, action) => lastState.current = reducer(state, action),
        initState,
        initializer
      ),
      getState
    ]
  }

const FormPreview = ({form, company, contact, config, setFinished, isIframe}) => {

    const reducer = useCallback((state, action) => {

        if(action.SET_ALL) {
            const {SET_ALL, ...parsedAction} = action
            return {...state, ...parsedAction}
        }

        const newState = {...state, [action.id]: action.value};

        // if the question being changed is set to calculate the total of all answers
        // run that here and set property to state
        if(action.calculate_total) {
            const roodId = getRootId(action.id);
            let total = 0;
            Object.keys(newState).forEach(key => {
                if(key.includes(roodId) && !key.includes('-total')) {
                    const answer = parseFloat(newState[key]);
                    if(!Number.isNaN(answer)) total += answer;
                    
                }
            })
            newState[roodId + '-total'] = total;
        }

        return newState
    }, [])

    // const [state, dispatch] = useReducer(reducer, {});
    const [state, dispatch, getState] = useEnhancedReducer(reducer, {hi: 'test'});
    
    const [allQuestions]                        = useState(form.questions || []);
    const [err, setErr]                         = useState(false);
    const [saveInterval, setSaveInterval]       = useState(false);
    const [loaded, setLoaded]                   = useState(false);
    const [shownQuestions, setShownQuestions]   = useState([]);
    const [page, setPage]                       = useState(0);
    const [answers, setAnswers]                 = useState({});
    const [errors, setErrors]                   = useState([]);

    const isLastPage = page === (form.pages - 1) ? true : false;

    const onSave = useCallback(async() => {
        const saved = await _form_answers.save({
            company   : company._id,
            form      : form._id,
            contact   : contact._id,
            answers   : getState(),
        })
        if(!saved.success) {
            
        }
    }, [getState, company._id, form._id, contact._id])

    const onChange = useCallback((question, e) => {
        let value = e.target ? e.target.value : e;
        if(question.type === "social-security") {
            value += '__|REDACTED|__'
        }
        dispatch({...question, value})
    }, [dispatch])

    const onSubmit = useCallback(async () => {
        toggleStandardLoader(true)

        const submitted = await _forms.submit({
            form: form._id,
            contact: contact._id,
            company: company._id,
            answers: getState(),
        })
        toggleStandardLoader(false)

        if(!submitted.success) {
            return toggleAlertBS(true, 'Please Check Your Internet Connection And Try Again');
        }

        if(form.redirect_url) {
            window.location.href = form.redirect_url
        } else {
            setFinished(submitted.data)
        }

    }, [form._id, form.redirect_url, contact._id, company._id, getState, setFinished])

    const onSetPage = useCallback((page, questions, ignoreState) => {
        const _questions = questions ? JSON.parse(JSON.stringify(questions)) : JSON.parse(JSON.stringify(allQuestions))
        const filteredQuestions = _questions.filter(q => q.page === page);
        setPage(page)
        setShownQuestions(filteredQuestions)
        window.scrollTo(0,0)
        if(!ignoreState) setAnswers(state)
    }, [allQuestions, state])

    const moveForward = useCallback(() => {
        const _state = getState()
        const calculate = getProgressAndErrors(shownQuestions, _state)
        setErrors(calculate.errors)

        if(calculate.errors.length) {
            let el = document.getElementById(calculate.errors[0]);
            if(el) {
                if(el.getAttribute('type') === 'hidden') el = el.parentElement;
                el.scrollIntoView({block: 'center', })
            }
        } else {
            if(page + 1 < form.pages) {
                onSetPage(page + 1)
            } else {
                onSubmit()
            }
        }
    }, [shownQuestions, getState, onSetPage, form.pages, page, onSubmit])
    
    const moveBack = useCallback(() => {
        if(page - 1 >= 0)  onSetPage(page - 1)
    }, [onSetPage, page])

    const setStartingPage = useCallback((questions, answers, form) => {
        const tryPages = (page = 0) => {
            // catch all incase of infinite loop
            if(page > config.MAX_FORM_PAGES) return setErr(true);
            // if we are done on all pages, show the first page
            if(page + 1 > form.pages) return onSetPage(0, questions, true)
            const _questions = questions.filter(p => p.page === page)
            const calculate = getProgressAndErrors(_questions, answers)
            // if we have errors on a page, stop and show that page
            if(calculate.errors.length) {
                return onSetPage(page, questions, true)
            } else {
                // try to show the next page if it has errors, if not
                // continue looping through pages
                tryPages(page + 1)
            }
        }
        tryPages()
    }, [onSetPage, config.MAX_FORM_PAGES])
    
     // return form from database if _id is set
     const fetchData = useCallback(async() => {
         const values = await Promise.all([
            _form_answers.find(company._id, form._id, contact._id)
         ])
        const formAnswers = values[0]

        if(formAnswers.success) {
            const _answers = formAnswers.data && formAnswers.data.answers ? formAnswers.data.answers : {};

            setStartingPage(allQuestions, _answers, form)
            setLoaded(true);
            if(Object.keys(_answers).length) {
                dispatch({..._answers, SET_ALL: true})
                setAnswers(_answers)
            }

            setSaveInterval(setInterval(onSave, config.SAVE_INTERVAL))

        } else {
            setErr(true);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, allQuestions, company._id, form._id, contact._id, dispatch])

    useEffect(() => {
        return () => {
            if(saveInterval) clearInterval(saveInterval)
        }
    }, [saveInterval])


    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(err) return <div className="alert alert-warning text-center"><i className="fas fa-exclamation-triangle mr-2 " /> Please refresh your page and try again.</div>
    // if(redirect) return <Redirect to={redirect} />
    if(!loaded || !shownQuestions.length) return <div className="py-6"><Circle /></div>

    return (
        <div id="page-form-action" className="py-3" >

            <PageProgress 
                form={form}
                state={state}
                currentPage={page + 1}
                _questions={shownQuestions}
                isIframe={isIframe}
            />

            <div className="main-wrapper">

                <div className='borde my-4 pt-4 '>
                    <Container className="z-depth border-right border border-left bg-white">
                        <StepTracker 
                            form={form}
                            currentPage={page + 1}
                            onChangeStep={(page) => onSetPage(page)}
                            moveForward={moveForward}
                            moveBack={moveBack}
                            isLastPage={isLastPage}
                        />
                    </Container>
                </div>

                <Container className="z-depth- border bg-white">
                    <Form id="archk-form" onSubmit={(e) => e.preventDefault()}>
                        <MapQuestions 
                            _questions={shownQuestions} 
                            onChange={onChange}
                            answers={answers}
                            form={form}
                            errors={errors}
                            config={config}
                            getState={getState}
                        />
                    </Form>
                </Container>

                <div className="border-top pt-4 mt-4 pb-4 bg-white footer">
                    <Container>
                        <Row>
                            <Col xs={6}>
                                {page !== 0 ? (
                                    <button onClick={moveBack} className="px-4 btn btn-info"><i className="fas fa-arrow-left mr-2" /> Go Back</button>
                                ) : null}
                            </Col>
                            <Col xs={6} className="text-right">
                                <button onClick={moveForward} className="px-4 btn btn-success">{isLastPage ? 'Next Page' : 'Next Page'} <i className="fas fa-arrow-right ml-2 " /></button>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )

}

export default FormPreview