import PropTypes from 'prop-types';
import React from "react";
import { FormGroup, Input } from 'reactstrap';

class DragAndDropFileNameInput  extends React.Component {

    state = {
        name: ''
    }

    onFriendlyNameChange = (e, file, index) => {

        this.setState({name: e.target.value});
        this.props.onFriendlyNameChange(e, file, index);

    }

    componentDidMount = () => {

        const { file, defaultValue } = this.props
        const name = file.friendly_name ? file.friendly_name : defaultValue ? defaultValue : '';
        this.setState({name})

    }

    render() {

        const { file, index, zone_id, friendly_nameState, onRemoveFileForUpload, hideNameChange } = this.props
        const { name } = this.state

        return (

            <FormGroup >
                <label className="form-control-label d-block">
                    Document Name * 
                    <span 
                        onClick={() => {onRemoveFileForUpload(file, index)}} 
                        className="cursor-pointer float-right text-danger font-weight-bold"
                    >
                        Remove
                    </span>
                </label>

                {!hideNameChange ? (
                    <Input
                        id={`drop-zone-name-${zone_id}-${index}`}
                        value={name}
                        onChange={(e) => this.onFriendlyNameChange(e, file, index)}
                        type="text"
                        className="rounded"
                    />
                ) : null}
                
                {friendly_nameState ? (
                    <p className="mb-0 mt-2 text-danger text-sm font-weight-bold">{friendly_nameState}</p>
                ) : null}
            </FormGroup>

        );
    }
}

DragAndDropFileNameInput.propTypes = {
    file                  : PropTypes.object.isRequired,
    zone_id               : PropTypes.string.isRequired,
    index                 : PropTypes.number.isRequired,
    onRemoveFileForUpload : PropTypes.func.isRequired,
    defaultValue          : PropTypes.string,
    friendly_nameState    : PropTypes.string,
};

export default DragAndDropFileNameInput

