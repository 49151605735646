import keys from 'keys';
import { Helmet } from 'react-helmet';

import { Col, Container, Row, Card, CardHeader, CardTitle, CardFooter } from "reactstrap";

const Confirmation = ({company}) => {

    const logo = company.branding && company.branding.logo ? company.branding.logo : keys.LOGO
    const companyNameColor = company.branding && company.branding.name_color ? company.branding.name_color : '#525f7f'
    const companyLogoWidth = company.branding && company.branding.logo_width ? parseInt(company.branding.logo_width) : 300
    const hideName = company.branding && company.branding.hide_name ? company.branding.hide_name : false

    return (
        <>

			<Helmet>
				<title>{company ? `${company.name} - Powered By Zap Reports` : 'Powered By Zap Reports'}</title>
				<meta name="description" content={company ? `${company.name} - Powered By Zap Reports` : 'Zap Reports'} />
			</Helmet>

            <div className="header  pt-5 pb-6 pb-md-8 py-lg-8 pt-lg-9">
                <Container className="pb-4">
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <img style={{width:  companyLogoWidth, position: 'relative',}} alt="..." className={!hideName ? "mb-4" : null}  src={logo} />
                                {!hideName ? (
                                    <h3 className="display-4 mb-0" style={{color: companyNameColor}}>{company.name}</h3>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container className="mt--9 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">

                        <div className="text-center mb-6 mt--3">
                            <h2 className="h1"><i className="fas fa-check-circle " style={{fontSize: 80, color: companyNameColor}}/></h2>
                        </div>

                        <Card className="bg-white border-0  position-relative text-center" >

                            <CardHeader>
                                <CardTitle className="mb-0">Thats It, You're All Done!!</CardTitle>
                            </CardHeader>

                            <CardFooter>
                                <p className="mb-0">This form has been submitted successfully, you may now close or leave this page</p>
                            </CardFooter>

                        </Card>

                    </Col>
                </Row>
            </Container>


		</>

    )
}

export default Confirmation
