import { memo, useState, useEffect } from 'react';
import Question from './Question';
import getProgressAndErrors from './_utils/getProgressAndErrors'

const MapQuestions = ({_questions, onChange, answers, form, errors, loopTitle, nest = 0, loopIndex = 0, parentIsLoop, config, isWeekLoop, getState}) => {

    const [hasAllAnswers, setHasAllAnswers] = useState(false)
    const [hideQuestions, setHideQuestions] = useState(false)

    useEffect(() => {
        if(isWeekLoop) {
            const _state = getState();
            const calculate = getProgressAndErrors(_questions, _state, loopIndex)

            if(calculate.progress === '100.00') {
                setHasAllAnswers(true)
                setHideQuestions(true)
            }

        }
    }, [isWeekLoop, getState, _questions, loopIndex])

    let className = 'question-list-inner';
    if(hasAllAnswers) className += ' has-all-answers'
    if(hasAllAnswers && hideQuestions ) className += ' has-all-answers-closed'


    return (
        <div className="question-list position-relative" style={{paddingLeft: nest * 20}} >
    
            <i className="indent-icon fas fa-reply text-dark fa-rotate-180" style={{marginLeft: (nest - 1) * 20}} />
    
            <div 
                className={className} 
                onClick={hasAllAnswers && hideQuestions ? () => setHideQuestions(!hideQuestions) : null}
            >
    
                {parentIsLoop ? (
                    <h2 
                        className="item-identifier"
                        onClick={hasAllAnswers && !hideQuestions ? () => setHideQuestions(!hideQuestions) : null}
                    >
                        {hideQuestions ? <i className="fas fa-check text-success mr-3 " /> : null}
                        {loopTitle ? loopTitle : `ITEM: ${loopIndex}`}
                    </h2>
                ) : null}


    
                <div className={hideQuestions ? 'd-none' : ''}>
                    {_questions.map((question, i) => (
                        <Question 
                            key={question.id}
                            question={question}
                            onChange={onChange}
                            answers={answers}
                            form={form}
                            nest={nest}
                            loopIndex={loopIndex}
                            errors={errors}
                            parentIsLoop={parentIsLoop}
                            isWeekLoop={isWeekLoop}
                            config={config}
                            i={i}
                            getState={getState}
                        />
                    ))}
                </div>
               
            </div>
        </div>
    )
}

export default memo(MapQuestions);