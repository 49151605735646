/*
Documentation

this page makes the user confirm the company they are logging into is correct

*/

import keys from 'keys';

import { useState, useCallback } from 'react';
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Form, Row } from "reactstrap";

import { Helmet } from 'react-helmet';

import VerificationCompanyIcon from './_images/VerificationCompany.svg'

import ModalTerms from './ModalTerms';
import ModalToggler from 'components/functional/modals/Toggler'

const Terms = ({setTermsAccepted, company, form}) => {

    const [err, setErr] = useState(false);
    const [agree, setAgree] = useState(false);

    const onVerify = useCallback((e) => {
        e.preventDefault();
        if(agree) return setTermsAccepted(true)
        setErr(true);

    }, [agree, setTermsAccepted])

    const logo = company.branding && company.branding.logo ? company.branding.logo : keys.LOGO;
    const companyNameColor = company.branding && company.branding.name_color ? company.branding.name_color : '#525f7f';
    const companyLogoWidth = company.branding && company.branding.logo_width ? parseInt(company.branding.logo_width) : 300;
    const hideName = company.branding && company.branding.hide_name ? company.branding.hide_name : false;

    const _name = company.name.length > 20 ? company.name.slice(0, 20) + '...' : company.name;
    const _form = form.name.length > 20 ? form.name.slice(0, 20) + '...' : form.name;

    return (
        <>
        
        <Helmet>
            <title>{`${_name} - ${_form}`}</title>
            <meta name="description" content={`${_name} - ${_form}`} />
        </Helmet>

        <div className="header  pt-5 pb-6 pb-md-8 py-lg-8 pt-lg-9">
            <Container className="pb-4">
                <div className="header-body text-center mb-7">
                    <Row className="justify-content-center">
                        <Col className="px-5" lg="6" md="8" xl="5">
                            <img style={{width:  companyLogoWidth, position: 'relative',}} alt="..." className={!hideName ? "mb-4" : null}  src={logo} />
                            {!hideName ? (
                                <h3 className="display-4 mb-0" style={{color: companyNameColor}}>{company.name}</h3>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
    
                    <Form role="form">
    
                        <Card className="bg-white border-0 mb-0 position-relative" >
    
                            <img src={VerificationCompanyIcon} alt="..." className="flow-icon bg-white" />
    
                            <CardHeader>
                                <CardTitle className="mb-0">Your Almost There!</CardTitle>
                            </CardHeader>
    
                            <CardHeader>
                                <p>You are about to get started filling out the form "<b className="text-underline text-success">{form.name}</b>" for <b className="text-dark">{company.name}</b>.</p>
                                <p className="mb-">Any information you provide is <b className="text-dark">kept safe</b> and we will <b className="text-info text-underline">NOT SHARE OR DISCLOSE</b> your information to an outside party without your consent. </p>
                                <p className="m">This form <b className="text-info">automatically saves your progress</b> so you may come back at any time.</p>
                                <p className="mb-0">
                                    Before you get started please agree to the terms of service{' '}
                                    <span className="text-underline text-dark cursor-pointer"><ModalToggler component={ModalTerms}>listed here.</ModalToggler></span>{' '}
                                    by checking the box below{' '}
                                </p>
                            </CardHeader>
    

                            <CardHeader>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="confirm-terms-of-service"
                                        type="checkbox"
                                        onChange={(e) => setAgree(!agree)}
                                    />
                                    <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                                        I agree to the Terms of Service
                                    </label>
                                </div>
                            </CardHeader>

                            {err ? (
                                <CardHeader>
                                    <p className="text-sm font-weight-bold text-warning mb-0">
                                        <i className="fas fa-arrow-up mr-2 " />
                                        Please agree to the Terms of Service above before proceeding
                                    </p>
                                </CardHeader>
                            ) : null}
    
                            <CardFooter className="text-center">
                                <button id="loginButton" onClick={onVerify} className="my-2 btn-lg btn-block btn btn-success">
                                    Click To Start!
                                </button>
                            </CardFooter>
                        </Card>
    
                    </Form>
    
                </Col>
            </Row>
        </Container>

        </>
    )

}

export default Terms

