import _companies from '_functions/companies';
import _forms from '_functions/forms';
import _contacts from '_functions/contacts';

import { useEffect, useState, useCallback } from 'react';

import Main from './Main';
import Login from './Login';
import Confirmation from './Confirmation';
import Terms from './Terms';

import UtilityAccessDenied from './Utility/AccessDenied'
import UtilityCloseIframe from './Utility/CloseIframe'

import { getUrlParameter } from 'utils/urls'

const Forms = ({match}) => {

    const [err, setErr]                       = useState(false);
    const [config, setConfig]                 = useState(false);
    const [termsAccepted, setTermsAccepted]   = useState(false);
    const [finished, setFinished]             = useState(false)
    const [parentDomain, setParentDomain]     = useState(false);
    const [isIframe, setIsIframe]             = useState(false);
    const [accessDenied, setAccessDenied]     = useState(false);

    const [state, setState] = useState({
        form: null,
        contact: null,
        company: null
    })

    const { form, contact, company } = state;

    const onSetContact = useCallback((_contact) => {
        const _state = JSON.parse(JSON.stringify(state));
        _state['contact'] = _contact
        setState(_state)
    }, [state])

    const onSetFinished = useCallback((value) => {
        if(isIframe && parentDomain) {
            window.parent.postMessage({
                type: 'zapReportsForms',
                event: 'onSubmit',
                data: value
            }, parentDomain)
        }
        setFinished(true)
    }, [isIframe, parentDomain])

    const fetchContact = useCallback(async (_company) => new Promise (async resolve => {
        const identifier = getUrlParameter('i')
        const access_code = getUrlParameter('a');

        if(!identifier || !access_code) return resolve();

        const verified = await _contacts.verify({
            phone: identifier, 
            email: identifier, 
            access_code, 
            company: _company._id 
        })
        return resolve(verified.data)
    }), []);
   
    const fetchForm = useCallback(async (_company) => new Promise (async resolve => {
        const form = await _forms.findByNumber(_company._id, match.params.form_number);
        return resolve(form.data)
    }), [match.params.form_number]);

    const fetchConfig = useCallback(async () => new Promise (async resolve => {
        const _config = await _forms.getConfig();
        setConfig(_config.data)
        return resolve(_config.data ? true : false)
    }), []);

    const fetchCompany = useCallback(async () => new Promise (async resolve => {
        let name = match.params.company_name;
        if(name) name = name.replace(/\+/g, ' ')

        const company = await _companies.findByName(name);
        return resolve(company.data)
    }), [match.params.company_name]);

    const fetchData = useCallback(async () => {
        
        const _company = await fetchCompany();

        if(window.location !== window.parent.location) {    
            // if we are an iframe return access denied if the docmain has not been whitelisted
            // example whitelist: http://localhost:3000/
            var url = (window.location !== window.parent.location) ? document.referrer: document.location.href;
            if(!_company.authorized_embed_domain || (_company.authorized_embed_domain && !_company.authorized_embed_domain.includes(url)) ) {
              
                return setAccessDenied(true)
            }
        } 

        if(!_company) return setErr(true);
    
        const values = await Promise.all([
            fetchForm(_company),
            fetchContact(_company),
            fetchConfig(),
        ])

        if(!values[0]) return setErr(true)
        if(!values[2]) return setErr(true)

        setState({
            form: values[0],
            company: _company,
            contact: values[1]
        })

    }, [fetchForm, fetchCompany, fetchContact, fetchConfig])

    const onMessageReceived = useCallback((message) => {
        if(message && message.data && message.data.type === 'zapReportsForms') {
            if(message.data.event === 'onLoad') {
                setParentDomain(message.data.data.domain)
            }
        }
    }, [])

    useEffect(() => {
        if(window.location !== window.parent.location) {
            setIsIframe(true);
            window.addEventListener("message", onMessageReceived)
        } 

        return () => {
            if(window.location !== window.parent.location) {
                setIsIframe(true);
                window.removeEventListener("message", onMessageReceived)
            } 
        }

    }, [onMessageReceived])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <div className="alert alert-warning text-center"><i className="fas fa-exclamation-triangle mr-2 " /> Please refresh your page and try again.</div>
    if(accessDenied) return  <UtilityAccessDenied isIframe={isIframe} parentDomain={parentDomain} />

    if(!form || !company) return <div />

    if(!form.active) return <div className="alert alert-warning text-center"><i className="fas fa-exclamation-triangle mr-2 " /> This link has expired.</div>

    return (
        <div>
            <UtilityCloseIframe isIframe={isIframe} parentDomain={parentDomain} />
            { 
                !contact ? <Login form={form} company={company} onSetContact={onSetContact} /> :
                !termsAccepted ? <Terms form={form} company={company} setTermsAccepted={setTermsAccepted} /> : 
                finished ? <Confirmation company={company} /> : 
                <Main isIframe={isIframe} form={form} company={company} contact={contact} config={config} setFinished={onSetFinished} />
            }
        </div>
    )
}

export default Forms;