const getRootId = (_id) => {
    const last2 = _id.slice(_id.length -2);
    const last3 = _id.slice(_id.length -3);

    if(last2.includes('-')) {
        _id = _id.slice(0, _id.length - 2)
    } else if(last3.includes('-')) {
        _id = _id.slice(0, _id.length - 3)
    }
    return _id;
}

export default getRootId;