import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import setQuestionWithId from '../_utils/setQuestionWithId'

import _forms from '_functions/forms';

import Circle from 'components/markup/loading/Circle'

const Text = ({question, answers, onChange, form, loopIndex, errors}) => {

    const [loaded, setLoaded] = useState(false)
    const [err, setErr] = useState(false)
    const [preview, setPreview] = useState({})
    const [_question, _setQuestion] = useState({})
    const [id] = useState(uuidv4())
    const [hideUploader, setHideUploader] = useState(false);

    const hasErr = errors.includes(_question.id) && !preview.document ? true : false;

    const fetchDocument = useCallback(async (value) => {
        const document = await _forms.viewDocument(value)
        if(document.data && document.data.document) {
            setPreview(document.data)
            setHideUploader(true)
        }
        setLoaded(true)
    }, [])

    const onUpload = useCallback((e) => {
        setErr(false)

        try {
            const data = JSON.parse(e.xhr.response)

            if(data.message && data.message.length) {
                return setErr('Please try again.')
            }
            onChange(_question, data.data.document)
            fetchDocument(data.data.document)
        } catch(e) {
            setErr('Please try again.')
        }

    }, [_question, onChange, fetchDocument])

    const onUploadNew = useCallback(() => {
        setPreview({})
        setHideUploader(false)
    }, [])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        const value = answers[formattedQuestion.id];
        _setQuestion(formattedQuestion);
        if(value) {
            fetchDocument(value)
        } else {
            setLoaded(true)
        }
    }, [question, loopIndex, answers, fetchDocument])

    if(!form._id) return <></>;

    if(!loaded) return <div className="mb-3"><Circle /></div>

    return (
        <FormGroup id={_question.id} className="upload">        
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {preview && preview.document ? <i className="fas fa-check-circle text-success mr-2 " /> : null} {_question.name}
            </label>
            {question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
            ) : null}
            {err ? <div className="alert alert-danger mt-3">{err}</div> : null}

            {preview.document ? (
                preview.document.includes('image') ? (
                    <Row className="mb-3 border-to mt-4 border-botto py-3">
                        <Col sm={6} className="align-self-center text-left text-md-right order-xs-1 order-lg-2">
                            <img src={preview.document} className="border z-depth-1" alt="uploaded file" />
                        </Col>
                        <Col sm={6} className="align-self-center order-xs-2 order-lg-1">
                            <div className="text-sm mb-0">Uploaded File:</div>
                            <div className=" mb-0 mt-0 font-weight-bold">{preview.name}</div>
                            <button className="btn btn-outline-info mt-3" onClick={onUploadNew} >Click Here To Upload A Different File</button>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mb-3 border-to mt-4 border-botto py-3">
                        
                        <Col sm={6} className="align-self-center text-left text-md-right order-xs-1 order-lg-2">
                            <p className="text-sm"><i className="fas fa-info-circle text-info mr-2 " /> No Preview Is Available For This Document</p>
                        </Col>
                        <Col sm={6} className="align-self-center order-xs-2 order-lg-1">
                            <div className="text-sm mb-0">Uploaded File:</div>
                            <div className=" mb-0 mt-0 font-weight-bold">{preview.name}</div>
                            <button className="btn btn-outline-info mt-3" onClick={onUploadNew} >Click Here To Upload A Different File</button>

                        </Col>
                    </Row>
                )
            ) : null}
            {!hideUploader ? (
                <DragAndDrop
                    zone_id={id}
                    url={`/v1/api/forms/documents/${form._id}`}
                    onError={(e) => {
                        console.log(e)
                        if(e.message) {
                            setErr(e.message)
                        } else {
                            setErr('Please try again.')
                        }
                    }}
                    resizePixels={1200}
                    onUpload={onUpload}
                    defaultValue="File" 
                    uploadOne={true}
                    hideNameChange={true}
                    hasErr={hasErr}
                />
            ) : null}
            
            
        </FormGroup>  
    )
}

export default memo(Text);