import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import focusNextQuestionFromSelect from '../_utils/focusNextQuestionFromSelect'

import SelectMenu from 'react-select';

import MapQuestions from '../MapQuestions'

const State = ({question, answers, onChange, loopIndex, errors, form, nest, config}) => {
    
    const [_question, _setQuestion] = useState({})
    const [val, setVal] = useState('')

    const { stateOptions } = config;
    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const handleChange = useCallback((e) => {
        const value = e.value && e.value === '__FALSE__' ? '' : e.value;
        setVal(value);
        onChange(_question, value)
        setTimeout(() => {
            focusNextQuestionFromSelect(value, _question);
        }, 250)
    }, [_question, onChange])


    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        if(answers[formattedQuestion.id]) {
            setVal(answers[formattedQuestion.id])
        }
    }, [question, loopIndex, answers])

    return (
        <>
        <FormGroup key={_question._id}>
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                State
            </label>
            <SelectMenu 
                options={stateOptions}           
                onChange={handleChange}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                openMenuOnFocus={true}
                value={stateOptions.find(option => option.value === val)}
            />
            <Input 
                type="text" 
                style={{maxHeight: 0, padding: 0, border: 'none'}} 
                autoComplete="address-level1" 
                name="address-level1"
                onChange={(e) => {
                    const value = e.target.value;
                    if(!value) return;
                    const option = stateOptions.find(o => o.value.toLowerCase() === value.toLowerCase());
                    if(option) handleChange(option);
                }} 
            />
        </FormGroup>  

        {val && _question.branch_on_answers.includes(val) ? (
            <MapQuestions 
                _questions={_question.questions} 
                onChange={onChange}
                answers={answers}
                form={form}
                nest={nest + 1}
                loopIndex={loopIndex}
                errors={errors}
                config={config}
            />
        ) : null}
        </>
    )
}

export default memo(State);