import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import simulateTabOnEnter from '../_utils/simulateTabOnEnter'

const Text = ({question, answers, onChange, loopIndex, setActive, errors, config}) => {

    const maxLength = question.max_text_length ? question.max_text_length : question.is_possible_answer ? 30 : config.MAX_TEXT_LENGTH

    const [autoComplete, setAutoComplete] = useState('on')
    const [val, setVal] = useState('')
    const [_question, _setQuestion] = useState({})

    const length = val ? val.length : 0;
    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const handleChange = useCallback((e) => {
        let value = e.target.value;
        // if(value) value = value.trim();
        if(value && value.length > maxLength) value = value.slice(0,maxLength)
        setVal(value);
        onChange(_question, e)
    }, [_question, onChange, maxLength])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        setVal(answers[formattedQuestion.id] || '')

        let autoComplete = 'on';

        if(question.name) {
            if(question.name.includes('first name')) {
                autoComplete = "given-name"
            } else if(question.name.includes('last name')) {
                autoComplete = 'family-name'
            } else if(question.name.includes('phone number') || question.name.includes('cell phone') || question.name.includes('cellphone')) {
                autoComplete = 'phone'
            } else if(question.name.includes('email')) {
                autoComplete = 'email'
            }
        }
        setAutoComplete(autoComplete)
    }, [question, loopIndex, answers])

    return (
        <>
            <FormGroup>        
                <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                    {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                    {_question.name}
                </label>
                {question.description ? (
                    <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
                ) : null}
                <Input 
                    autoComplete={autoComplete}
                    
                    id={_question.id}
                    value={val}
                    type="text"
                    onChange={handleChange}
                    invalid={hasErr}
                    onBlur={() => setActive(false)}
                    onFocus={() => setActive(true)}
                    onKeyDown={simulateTabOnEnter}
                />
                {length > 100 || length >= maxLength ? (
                    <p className="text-right character-count">
                        <span className={length >= maxLength ? 'text-warning' : ''}>{length} / {maxLength} characters</span>
                    </p>
                ) : null}
                
            </FormGroup>  
        </>
    )
}

export default memo(Text);