import { memo, useState, useCallback, useEffect, useRef } from 'react';
import { FormGroup } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import focusNextQuestionFromSelect from '../_utils/focusNextQuestionFromSelect'

import DatePicker from 'react-datepicker'

const DateInput = ({question, answers, onChange, setActive, loopIndex, errors}) => {

    const calendar = useRef({})

    const [val, setVal] = useState(null)
    const [_question, _setQuestion] = useState({})

    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const handleChange = useCallback((e) => {
        const value = e;
        setVal(value);
        onChange(_question, e)
        setActive(false);
        if(value) {
            setTimeout(() => {
                focusNextQuestionFromSelect(value, _question);
            }, 250)
        }
    }, [_question, onChange, setActive])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        let answer = answers[formattedQuestion.id];
        if(answer) answer = new Date(answer)
        setVal(answer)
    }, [question, loopIndex, answers])

    return (
        <FormGroup className="position-relative">        
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`} htmlFor={_question.id}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {_question.name}
            </label>
            {_question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {_question.description}</p>
            ) : null}

            <DatePicker 
                id={_question.id}
                ref={calendar}
                selected={val} 
                onChange={handleChange} 
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        </FormGroup>  
    )
}

export default memo(DateInput);