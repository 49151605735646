import moment from 'moment'
import setQuestionWithId from './setQuestionWithId'

import phone from 'phone';

const questionDoesBranch = (question, answer) => {
    if(question.type === 'checkbox') {
        //  account for question with answer and then form change
        if(answer && Array.isArray(answer) && answer.some(v =>question.branch_on_answers.includes(v) )) {
            return true;
        }
    } else if(question.type === 'loop' || question.type === 'month-loop' || question.type === 'two-week-loop' || question.type === 'one-week-loop') {
        return true;
    } else if(question.branch_on_answer) {
        if(question.branch_on_answers.includes(answer)) {
            return true;
        }
    } 
  
    return false;
}

const isValidPhone = (answer) => {
    const valid = phone(answer);
    if(valid && valid[0]) return true
    return false;
}

const isValidSocialSecurity = (answer) => {
    return answer && answer.replace('__|REDACTED|__', '').toString().length === 9 ? true : false;
}

const isValidAddress = (question, answer) => {
    if(question.type === 'address' && answer) {
        if(answer.address_line_1 && answer.city && answer.state && answer.zip) {
            return true;
        }
    }
    return false;
}

const isValidCheckbox = (question, answer) => {
    if(question.type === 'checkbox' && answer && answer.length) return true;
    return false;
}

const isValidQuestion = (answer) => {
    if(answer) return true;
    return false;
}

const calculate = (_questions, state, _loopIndex) => {
    let total = 0;
    let complete = 0;
    let errors = [];

    const _setProgress = (questions, loopIndex = _loopIndex ? _loopIndex : 0) => {
        for (let i = 0; i < questions.length; i++) {
            const question = setQuestionWithId(questions[i], loopIndex);

            if(!question.required) continue;
            const answer = state[question.id] && state[question.id] === '__FALSE__' ? '' : state[question.id];
            total++;

            let isValid = false;


            switch (question.type) {
                case 'address': isValid = isValidAddress(question, answer); break;
                case 'checkbox': isValid = isValidCheckbox(question, answer); break;
                case 'phone': isValid = isValidPhone(answer); break;
                case 'social-security': isValid = isValidSocialSecurity(answer); break;
                default: isValid = isValidQuestion(answer); break;
            }
            if(questionDoesBranch(question, answer)) {

                let _loopIndex = loopIndex;

                if(question.type === 'loop') {
                    const number = parseInt(answer);
                    for (let ii = 0; ii < number; ii++) {
                        _setProgress(question.questions, ii + 1);
                    }
                } else if(question.type === 'month-loop') {
                    if(answer) {
                        const date = new Date(answer);
                        const daysInMonth = moment(date).daysInMonth();
                        for (let ii = 0; ii < daysInMonth; ii++) {
                            _setProgress(question.questions, ii + 1);
                        }
                    }
                  
                } else if(question.type === 'two-week-loop') {
                    if(answer) {
                        for (let ii = 0; ii < 14; ii++) {
                            _setProgress(question.questions, ii + 1);
                        }
                    }
                  
                } else if(question.type === 'one-week-loop') {
                    if(answer) {
                        for (let ii = 0; ii < 7; ii++) {
                            _setProgress(question.questions, ii + 1);
                        }
                    }
                  
                } else {
                    _setProgress(question.questions, _loopIndex);
                }
            }

            if(isValid) {
                complete++;
            } else {
                errors.push(question.id);
            }
            
        }

    }
    
    _setProgress(_questions)

    const progress = ((complete / total) * 100 ).toFixed(2);
    return { progress, errors }

}

export default calculate;