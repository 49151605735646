import { memo, useState, useCallback, useEffect, useRef } from 'react';
import { FormGroup } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import focusNextQuestionFromSelect from '../_utils/focusNextQuestionFromSelect'

import MapQuestions from '../MapQuestions'

import DatePicker from 'react-datepicker'
import moment from 'moment';

const MonthLoop = ({question, answers, onChange, setActive, loopIndex, errors, config, form, nest, getState}) => {

    const calendar = useRef({})

    const [maps, setMaps] = useState(null)
    const [val, setVal] = useState(null)
    const [_question, _setQuestion] = useState({})

    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const setMapQuestions = useCallback((e) => {
        if(!e) return;
        // this may not be set at render, if so don't return anything
        if(!_question.id) return;
        const daysInMonth = moment(e).daysInMonth();
        let _maps = [];

        for (let i = 0; i < daysInMonth; i++) {
            const day = moment(e).add(i, 'days')
            const loopTitle = day.format('dddd - MMM Do, YYYY')

            _maps.push(
                <MapQuestions 
                    key={i}
                    loopTitle={loopTitle}
                    _questions={_question.questions} 
                    onChange={onChange}
                    answers={answers}
                    form={form}
                    nest={nest + 1}
                    loopIndex={i + 1}
                    errors={errors}
                    parentIsLoop={true}
                    config={config}
                    getState={getState}
                />
            )
            
        }
        setMaps(_maps)
    }, [_question, onChange, answers, nest, form, errors, config, getState])

    const handleChange = useCallback((e) => {
        const value = e;
        setVal(value);
        onChange(_question, e)
        setActive(false);
        setMapQuestions(e)
        if(value) {
            setTimeout(() => {
                focusNextQuestionFromSelect(value, _question);
            }, 250)
        }
    }, [_question, onChange, setActive, setMapQuestions])

    // whenever errors change rerender questions to show the errors
    useEffect(() => {
        setMapQuestions(val)
    }, [errors, setMapQuestions, val])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        let answer = answers[formattedQuestion.id];
        if(answer) answer = new Date(answer)
        setVal(answer)
    }, [question, loopIndex, answers])

    useEffect(() => {
        if(val) return;
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        let answer = answers[formattedQuestion.id];
        if(answer) answer = new Date(answer)

        setVal(answer || null)
        setMapQuestions(answer);
    }, [question, loopIndex, answers, setMapQuestions, val])

    return (
        <FormGroup className="position-relative">        
            <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`} htmlFor={_question.id}>
                {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                {_question.name}
            </label>
            {_question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {_question.description}</p>
            ) : null}


            <DatePicker 
                id={_question.id}
                ref={calendar}
                selected={val} 
                onChange={handleChange} 
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                showMonthYearPicker
                dropdownMode="select"
            />
            {maps}
        </FormGroup>  
    )
}

export default memo(MonthLoop);