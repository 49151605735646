import keys from 'keys';
import { Redirect } from 'react-router-dom';
import { useState, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Col, Container, Row, Card, CardHeader, CardTitle, FormGroup, Input, Form, CardFooter } from "reactstrap";

import { toggleAlertBS } from 'store/functions/system/system';

import StartIcon from './_images/start.svg'

import _companies from '_functions/companies';

const Reports = ({company, form, onSetContact}) => {

    const [loading,         setLoading]      = useState();
    const [err,             setErr]           = useState(false);
    const [identifier,      setIdentifier]    = useState('');

    const onVerify = useCallback(async (e) => {
        e.preventDefault();

        if(!identifier) return setErr(`Please add your mobile phone number or email address.`)

        setLoading(true);

        const register = await _companies.registerForm({
            identifier    : identifier,
            company_id    : company._id,
            form_id       : form._id,
        })

        if(register.success && register.data && register.data.contact) {
            toggleAlertBS('info', `Your account has been registered, a link has been sent to your phone or email address so you can come back here at any time.`)
            onSetContact(register.data.contact)

            const i =  register.data.contact.phone ? register.data.contact.phone : register.data.contact.email;
            const a = register.data.contact.access_code;
            window.history.pushState(null, null, `${window.location.href}?i=${i}&a=${a}`);
        } else {
            setLoading(false)
            setErr(register.message ? register.message[0] : `Something's not right, please refresh your page and try again.`)
        }

    }, [company, identifier, onSetContact, form._id])

  
    if(company === 'not found') return <Redirect to="/" />;
    if(!company._id) return <div />

    const logo = company.branding && company.branding.logo ? company.branding.logo : keys.LOGO
    const companyNameColor = company.branding && company.branding.name_color ? company.branding.name_color : '#525f7f'
    const companyLogoWidth = company.branding && company.branding.logo_width ? parseInt(company.branding.logo_width) : 300
    const hideName = company.branding && company.branding.hide_name ? company.branding.hide_name : false

    return (
        <>

			<Helmet>
				<title>{company ? `${company.name} - Powered By Zap Reports` : 'Powered By Zap Reports'}</title>
				<meta name="description" content={company ? `${company.name} - Powered By Zap Reports` : 'Zap Reports'} />
			</Helmet>

            <div className="header  pt-5 pb-6 pb-md-8 py-lg-8 pt-lg-9">
                <Container className="pb-4">
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <img style={{width:  companyLogoWidth, position: 'relative',}} alt="..." className={!hideName ? "mb-4" : null}  src={logo} />
                                {!hideName ? (
                                    <h3 className="display-4 mb-0" style={{color: companyNameColor}}>{company.name}</h3>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container className="mt--9 pb-5">
                <Row className="justify-content-center">
                    <Col lg="5" md="7">

                        <Form role="form" style={{zIndex: 2, position: 'relative'}}>

                            <Card className="bg-white border-0 mb-0 position-relative" >

                                <img src={StartIcon} alt="..." className="flow-icon bg-white" />

                                <CardHeader>
                                    <CardTitle className="flow-icon-header mb-0">Register Your Account</CardTitle>
                                </CardHeader>

                                <CardHeader>
                                    <p className="m text-sm">Our goal is to make getting your information <b className="text-underline">as easy as possible</b>.</p>
                                    <p className="m text-sm">All you need to do is <b className="text-success">1)</b> register your account with your email or phone number below <b className="text-success">2)</b> and you'll be able to fill out the form <b className="text-underline">{form.name}</b> for <b className="text-underline">{company.name}</b> right away.</p>
                                    <p className="mb-0 text-sm"><b className="text-info">Let's get started,</b> please enter your email address or mobile phone number below. </p>
                                </CardHeader>

                                <CardHeader >
                                    <FormGroup>
                                        <label className="form-control-label">Enter Your Email / Phone*</label>
                                        <Input
                                            name="identifier"
                                            type="text"
                                            value={identifier}
                                            invalid={err ? true : false}
                                            onChange={(e) => setIdentifier(e.target.value)}
                                        />
                                    </FormGroup>
                                </CardHeader>
                                <CardHeader className="bg-secondary">
                                    <p className="text-sm mb-0">**By opting in to create an account you authorize Zap Reports to send you automated email and text message reminders about your account status with <b className="text-underline">{company.name}</b>. You may opt out of these messages at any time. We will never disclose your information to a third party without your consent.</p>
                                </CardHeader>

                                {err ? (
                                <CardHeader>
                                    <p className="mb-0 text-sm text-danger font-weight-bold">{err}</p>
                                </CardHeader>
                                ) : null}

                                <CardFooter className="text-center">
                                    <button disabled={loading} id="loginButton" onClick={onVerify} className="my-2 btn-block btn btn-success btn-lg">
                                        <i className="fas fa-lock mr-2 " /> Continue
                                    </button>
                                </CardFooter>
                            </Card>

                        </Form>

                    </Col>
                </Row>
            </Container>

		</>

    )
}

export default Reports
