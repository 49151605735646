import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup } from 'reactstrap';

import setQuestionWithId from '../_utils/setQuestionWithId'
import focusNextQuestionFromSelect from '../_utils/focusNextQuestionFromSelect'

import MapQuestions from '../MapQuestions'

import SelectMenu from 'react-select'

const Select = ({question, answers, onChange, loopIndex, errors, nest, form, config, getState}) => {

    const [val, setVal] = useState('')
    const [_question, _setQuestion] = useState({answers: []})

    const hasErr = errors.includes(_question.id) && !val ? true : false;

    const handleChange = useCallback((e) => {
        const value = e.value && e.value === '__FALSE__' ? '' : e.value;
        setVal(value);
        onChange(_question, value)
        setTimeout(() => {
            focusNextQuestionFromSelect(value, _question);
        }, 250)
    }, [_question, onChange])

    const getFormattedValue = useCallback(() => {
        const answer = _question.answers.find(answer => answer === val);
        if(answer) return { value: answer, label: answer }
    }, [_question.answers, val])

    useEffect(() => {
        const formattedQuestion = setQuestionWithId(question, loopIndex);
        _setQuestion(formattedQuestion);
        setVal(answers[formattedQuestion.id] || '')
    }, [question, loopIndex, answers])

    return (
        <>
            <FormGroup>        
                <label className={`form-control-label ${hasErr ? 'text-danger' : ''}`}>
                    {hasErr ? <i className="fas fa-exclamation-triangle mr-2 " /> : null}
                    {_question.name}
                </label>            {question.description ? (
                    <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
                ) : null}

                <SelectMenu 
                    options={
                        _question.answers.map((answer, i) => {
                            return {value: answer, label: answer}
                        })
                    }           
                    onChange={handleChange}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    openMenuOnFocus={true}
                    value={getFormattedValue()}
                />
                <input type="hidden" id={_question.id} />

            </FormGroup>  
            {val && _question.branch_on_answers.includes(val) ? (
                <MapQuestions 
                    _questions={_question.questions} 
                    onChange={onChange}
                    answers={answers}
                    form={form}
                    nest={nest + 1}
                    loopIndex={loopIndex}
                    errors={errors}
                    config={config}
                    getState={getState}
                />
            ) : null}
        </>
    )
}

export default memo(Select);